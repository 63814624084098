import { navigate } from "gatsby";
import { setLocalStorageItem } from "../services/salesforce/helper";
import { useEffect } from "react";
import axios from "axios";

const HairQuizBasket = () => {
  useEffect(() => {
    const quizDataParams = new URLSearchParams(window.location.search)
      .get("cart")
      ?.split(";")
      .map(item => item.split(":"))
      .map(item => {
        return { productId: item[0], quantity: Number(item[1]) };
      });

    axios(`https://www.sheamoisture.com/.netlify/functions/salesforce-hairquiz-bag`, {
      method: "POST",
      data: quizDataParams
    })
      .then(quizData => {
        setLocalStorageItem("refresh_token", quizData.data.refresh_token, 90 * 24 * 60);
        setLocalStorageItem("access_token", quizData.data.access_token, 30);
        setLocalStorageItem("customerId", quizData.data.customerId, 3600);
        setLocalStorageItem("usid", quizData.data.usid, 3600);
        setLocalStorageItem("encUserId", quizData.data.encUserId, 3600);
        setLocalStorageItem("basketId", quizData.data.basketId, 60);
        typeof window !== `undefined` && navigate("/bag");
      })
      .catch(error => console.log(error));
  }, []);

  return null;
};

export default HairQuizBasket;
